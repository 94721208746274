.map .header {
  position: absolute;
  left: 0px;
  right: 0px;
}

.map .header h1.title {
  background-color: #444;
  color: white;
  font-size: 0.8em;
  text-align: center;
  line-height: 2em;
}

.map .footer {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 20px;
}
