.place-list {
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 300px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #444;
  padding: 0.5em 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.expand .place-list {
  bottom: 10px;
}

.place-list .title {
  text-align: center;
  background-color: #222;
  color: white;
  line-height: 2em;
  font-weight: bold;
  margin: 0 0.5em 0.5em;
  border-radius: 3px;
  grid-row: 1;
}

.place-list .list {
  grid-row: 2;
  overflow-x: hidden;
  overflow-y: scroll;
}

.contract .place-list .list {
  display: none;
}

.contract .place-list .title {
  margin-bottom: 0;
}

.place-list ul {
  padding: 0 0 0.5em;
}

.place-list li {
  list-style: none;
  line-height: 2em;
  padding: 0 0.5em;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  /* background-color: rgba(255, 255, 255, 1); */
}

.place-list li.prefecture {
  background-color: #f1f1f1;
  margin: 0 0.5em;
}

.place-list li.place:hover {
  background-color: #f1f1f1;
}

.place-list li svg {
  vertical-align: middle;
  margin-right: 0.5em;
}

.place-list li.selected {
  background-color: #f1f1f1;
}

.place-list .action button {
  grid-row: 3;
  margin: 0.5em 0.5em 0;
}