.autocomplete input {
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 7px 12px;
  border-radius: 3px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.075);
  font-size: 16px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  top: 10px;
  left: 340px;
  right: 30px;
}

@media screen and (max-width: 768px) {
  .autocomplete input {
    top: 2em;
    left: 10px;
    right: 10px;
  }
}