div.place-detail-info-window {
  max-width: 35em;
}

.place-detail-info-window .header {
  display: flex;
}

.place-detail-info-window .header .icon {
  margin-right: 0.5em;
}

.place-detail-info-window .header .title .name {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  padding: 6px 0 0 0;
  color: #444;
}

.place-detail-info-window .header .title .type {
  color: #777;
}

.place-detail-info-window .detail {
  background-color: #f1f1f1;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 12px;
  line-height: 21px;
  font-size: 13px;
}

.place-detail-info-window .description textarea {
  width: 100%;
  height: 10em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: white;
  padding: 6px;
}

.place-detail-info-window .description {
  background-color: #f1f1f1;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 12px;
  line-height: 21px;
  font-size: 13px;
}

.place-detail-info-window .description .title {
  color: #777777;
  font-weight: bold;
}

.place-detail-info-window .detail .title {
  color: #777;
  font-weight: bold;
}

.place-detail-info-window .form .actions {
  display: flex;
  cursor: pointer;
  margin-bottom: 1em;
}

.place-detail-info-window .form .actions .confirmed {
  display: flex;
  width: 100%;
}

.place-detail-info-window .from .action {
  display: inline-block;
  margin-top: 4px;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  padding: 6px;
  position: relative;
  left: -6px;
  opacity: .5;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.place-detail-info-window .form .action.delete {
  margin-left: auto;
}

.place-detail-info-window .form .action:hover {
  opacity: 1;
}

.place-detail-info-window .form .icon {
  margin-right: 0.5em;
}