.sign-in {
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
}

.sign-in h1 {
  margin-bottom: 1em;
}